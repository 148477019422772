.new-service-form {
    z-index: 1000;
    position: absolute; 
    top:  15vh;
    left: 35%;
    width: 40%;
    min-width: 500px;
    /* height: 55vh; */
    height: 75vh;
    border-radius: 10px;
    background-color: #fff;
    border: 2px solid #83b6f8;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.new-service-form .header {
    width: 100%; 
    height: 5vh;
    padding-left: 15%;
    padding-right: 2%;
}

.new-service-form .header .title {
    width: 80%;
    text-align: center;
    font-weight: bold;
    color: #000;
    font-size: 1.3em;
}

.new-service-form .header .close {
    line-height: 7vh;
    cursor: pointer;
    color: #000;
}

.new-service-form .header .close:hover {
    color: #83b6f8;
}

.new-service-form .body {
    width: 100%;
    height: 55vh;  
    padding-top: 5vh;
    text-align: left;
}

.new-service-form .body form {
    width: 67%;
    margin: auto;
}

.new-service-form .body form .form-item {
    margin-bottom: 3vh;
}

.new-service-form .body form .form-item label {
    display: inline-block;
    width: 35%;
    min-width: 100px;
}

.new-service-form .body form .form-item input {
    width: 65%;
    border: 2px solid #000;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    /* line-height: 2.5vh; */
    line-height: 3vh;
    /* box-sizing: border-box; */
    border: 2px solid #1878F1;
}

.new-service-form .body form .form-item select {
    width: 35%;
    border: 2px solid #000;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    /* line-height: 2.5vh; */
    line-height: 3vh;
    /* box-sizing: border-box; */
    outline-color: #1878F1;
    outline-offset: 1px;
    border: 2px solid #1878F1;
}

.new-service-form .body form .form-item input:focus {
    outline-color: #1878F1;
    outline-offset: 1px;
    border: 2px solid #1878F1;
}

.new-service-form .body form .form-item button {
    background-color: #1878F1;
    width: 100%;
    height: 6vh;
    border-radius: 10px;
    color: #fff;
    font-size: 1.2em;
}

.new-service-form .body form .form-item button:hover {
    background-color: #83b6f8;
}

.display-none-property {
    display: none;
}