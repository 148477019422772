.profile-container {
    width: 100%;
    height: 93vh;
}

.profile-container .profile-header {
    width: 100%;
    height: 5vh;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    background-color: #c6e2fa;
    background-color: #edf5ff;
    padding-left: 1%;
    /* padding-bottom: 0.25vh; */
    /* border-bottom: 1px solid #373737; */


}



.profile-container .profile-header .navigation {
    margin-top: 0.5vh;
    width: 7%;
    text-align: center; 
    cursor: pointer;
    margin-right: 1%;
}

.profile-container .profile-header .selected {

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 2px solid #f29219;
    /* color: #f29219; */
    /* font-weight: bold; */
}

.profile-container .profile-contents {
    width: 100%;
    height: 88vh;
}

.profile-container .profile-contents .services-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.profile-container .profile-contents .services-container .profile-info {
    width: 36%;
    height: 88vh;
    border-right: 1px solid lightgray;
    padding-left: 2%;
    padding-right: 2%;
    padding-top:  5vh;
    padding-bottom: 5vh;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: justify;

}

.profile-container .profile-contents .services-container .profile-info .profile-image img {
    border-radius: 50%;
}

.profile-container .profile-contents .services-container .profile-info .avgs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.profile-container .profile-contents .services-container .profile-info .avgs > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.profile-container .profile-contents .services-container .profile-info .avgs > div .icon {
    padding-right: 2%;
    color: #1878F1;
}

.profile-container .profile-contents .services-container .profile-info .avgs > div .value-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.profile-container .profile-contents .services-container .profile-info .avgs > div .value-text .text {
    font-size: 0.8em;
    color: gray;
    text-align: center;
}

.profile-container .profile-contents .services-container .services {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    padding-left: 2%;
    padding-right: 2%;
}

.profile-container .profile-contents .services-container .services .services-title {
    width: 100%;
    height: 7vh;
    line-height: 7vh;
    font-size: 1.3em;
}

.profile-container .profile-contents .services-container .services .services-nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border: 1px solid lightgray;
    border-radius: 15px;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1vh;
    padding-bottom: 1vh;
    margin-bottom: 3vh;
}

.profile-container .profile-contents .services-container .services .services-nav .nav {
    width: 10%;
    min-width: 100px;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}

.profile-container .profile-contents .services-container .services .services-nav .selected {
    background-color: #c6e2fa;
}

.profile-container .profile-contents .services-container .services .services-content {
    width: 100%;
    height: 71vh;

    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
}

.profile-container .profile-contents .services-container .services .services-content .content {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    justify-content: flex-start;
    flex-wrap: wrap;
}

.profile-container .profile-contents .services-container .services .services-content .service {
    width: 32%;
    min-width: 330px;
    height: 50vh;
    border: 1px solid lightgray;
    border-radius: 15px;
    margin-bottom: 2vh;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1vh;
    padding-bottom: 1vh;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    cursor: pointer;

    margin-right: 2%;
}

.profile-container .profile-contents .services-container .services .services-content .service .service-title {
    width: 100%;
    height: 4vh;
    line-height: 4vh;
    font-weight: bold;
    font-size: 1.2em;
}

.profile-container .profile-contents .services-container .services .services-content .service .service-description {
    width: 100%;
    height: 10vh;
    padding-top: 0.5vh;
    color: #1878F1;
} 

.profile-container .profile-contents .services-container .services .services-content .service .service-content-provider {
            
}

.profile-container .profile-contents .services-container .services .services-content .service .service-content-provider .title,
.profile-container .profile-contents .services-container .services .services-content .service .pricing-type .title {
    color: gray;
}

.profile-container .profile-contents .services-container .services .services-content .service .option-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.profile-container .profile-contents .services-container .services .services-content .service .option-1,
.profile-container .profile-contents .services-container .services .services-content .service .option-2 {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.profile-container .profile-contents .services-container .services .services-content .service .option label {
    padding-right: 5%;
}

.profile-container .profile-contents .services-container .services .services-content .service .option input {
    accent-color: #1878F1; 
    width: 17px;
    height: 17px;
    
}

.profile-container .profile-contents .services-container .services .services-content .service .post-length, 
.profile-container .profile-contents .services-container .services .services-content .service .price,
.profile-container .profile-contents .services-container .services .services-content .service .post-type {
    width: 100%;
    height: 6vh;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.profile-container .profile-contents .services-container .services .services-content .service .post-length span, 
.profile-container .profile-contents .services-container .services .services-content .service .price span,
.profile-container .profile-contents .services-container .services .services-content .service .post-type span {
    width: 10%;
    margin-right: 5%;
    color: #1878F1;
}

.profile-container .profile-contents .services-container .services .services-content .service .post-length p, 
.profile-container .profile-contents .services-container .services .services-content .service .price p,
.profile-container .profile-contents .services-container .services .services-content .service .post-type p {
    width: 90%;
}

.profile-container .profile-contents .services-container .services .services-content .service .price {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.profile-container .profile-contents .services-container .services .services-content .service .price span,
.profile-container .profile-contents .services-container .services .services-content .service .price strong {
    margin-right: 2%;
}

.profile-container .profile-contents .services-container .services .services-content .service .price input {
    width: 55%;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 1px;
    padding-bottom: 1px;
    text-align: right;
}

.profile-container .profile-contents .services-container .services .services-content .service .request-service {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.profile-container .profile-contents .services-container .services .services-content .service .request-service button {
    background-color: #1878F1;
    color: #fff;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 7px;
}

.profile-container .profile-contents .services-container .services .services-content .service .request-service button:hover {
    opacity: 0.8;
}

.profile-container .profile-contents .analytics {
    width: 100%;
    height: 88vh;
    padding-left: 2%;
    padding-right: 2%;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-snap-type: y mandatory;
}
.profile-container .profile-contents .analytics .analytics-engagement,
.profile-container .profile-contents .analytics .analytics-demographics {    
    scroll-snap-align: start;    

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    height: 88vh;
}

.profile-container .profile-contents .analytics .analytics-engagement .title,
.profile-container .profile-contents .analytics .analytics-demographics .title {
    width: 100%;
    height: 5vh;
    line-height: 5vh;
    margin-top: 1vh;
    font-size: 1.2em;
    border-bottom: 2px solid #f29219;
}

.profile-container .profile-contents .analytics .analytics-engagement .sub-header,
.profile-container .profile-contents .analytics .analytics-demographics .sub-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

}

.profile-container .profile-contents .analytics .analytics-engagement .sub-header .filter,
.profile-container .profile-contents .analytics .analytics-demographics .sub-header .filter {
    margin-top: 1vh;
}


.profile-container .profile-contents .analytics .analytics-engagement .navigation,
.profile-container .profile-contents .analytics .analytics-demographics .navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    margin-bottom: 3vh;
    width: 80%;
}

.profile-container .profile-contents .analytics .analytics-engagement .navigation .nav,
.profile-container .profile-contents .analytics .analytics-demographics .navigation .nav {
    width: 13%;
    min-width: 140px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 10px;
    text-align: center;
    margin-top: 1vh;
    margin-right: 1%;
    color: #1878F1;
    border: 2px solid #1878F1;   
    cursor: pointer; 
}

.profile-container .profile-contents .analytics .analytics-engagement .navigation .nav:hover,
.profile-container .profile-contents .analytics .analytics-demographics .navigation .nav:hover {
    background-color: #1878F1;
    /* background-color: #83b6f8; */
    color: #fff;
}

.profile-container .profile-contents .analytics .analytics-demographics .navigation .nav {
    width: 14%;
}

.profile-container .profile-contents .analytics .navigation .nav.selected {
    background-color: #1878F1;
    color: #fff;
}
.profile-container .profile-contents .analytics .analytics-engagement .content {
    width: 100%;
    height: 80vh;
    
    display: flex;
    flex-direction: row; 
    justify-content: space-between; 
    flex-wrap: wrap;
}

.profile-container .profile-contents .analytics .analytics-engagement .content.youtube-channel-metrics {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .graph-container.hidden {
    visibility: hidden;    
}

.profile-container .profile-contents .analytics .analytics-engagement .content .graph-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    border: 0.5px solid lightgray;    
    width: 32.5%;
    height: 23vh;
    border-radius: 15px;

    padding-left: 1%;
    padding-right: 1%;
}

.profile-container .profile-contents .analytics .analytics-engagement .content.content.youtube-channel-metrics .graph-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    border: 0.5px solid lightgray;    
    width: 49%;
    height: 35vh;
    border-radius: 15px;

    padding-left: 1%;
    padding-right: 1%;

}

.profile-container .profile-contents .analytics .analytics-engagement .content .graph-container .title {
    border: none;
    font-size: 1em;
    height: 3.5vh;
    line-height: 3vh;
    
    border-bottom: 1px solid #1878F1;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .graph-container .graph {
    width: 100%;
    height: 19vh;
}


.profile-container .profile-contents .analytics .analytics-engagement .content .website-clicks .graph {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .website-clicks .graph .content {
    width: 70%;
    height: 19vh;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .website-clicks .graph .changes {
    width: 30%;
    height: 19vh;
    padding-top: 1vh;
    padding-bottom: 1vh;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .website-clicks .graph .changes .quantity {
    font-weight: bold;
    font-size: 1.2em;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .website-clicks .graph .changes .increase {
    color: #22da22;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .website-clicks .graph .changes .text {
    color: gray;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .sentiment .graph {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .sentiment .graph .content {
    width: 80%;
    height: 17vh;
    padding-top: 1vh;        
}

.profile-container .profile-contents .analytics .analytics-engagement .content .sentiment .graph .value {
    width: 20%;
    height: 17vh;
    line-height: 17vh;
    font-size: 1.5em;
    font-weight: bold;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .follow-unfollow .graph {
    width: 80%;
    height: 17vh;
    padding-top: 1vh;        
}

.profile-container .profile-contents .analytics .analytics-demographics .content { 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profile-container .profile-contents .analytics .analytics-demographics .content .follower-demographics,
.profile-container .profile-contents .analytics .analytics-demographics .content .engaged-demographics, 
.profile-container .profile-contents .analytics .analytics-demographics .content .reached-demographics { 
    width: 32%;
    height: 70vh;
    border-radius: 10px;
    border: 1px solid lightgray;    

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.profile-container .profile-contents .analytics .analytics-demographics .content .follower-demographics .title,
.profile-container .profile-contents .analytics .analytics-demographics .content .engaged-demographics .title,
.profile-container .profile-contents .analytics .analytics-demographics .content .reached-demographics .title {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    margin-top: 0;
    margin-bottom: 1vh;
    border-bottom: 1px solid lightgray;
    padding-left: 5%;
    padding-right: 5%;

    font-size: 0.9em;
}

.profile-container .profile-contents .analytics .analytics-demographics .content .follower-demographics .content,
.profile-container .profile-contents .analytics .analytics-demographics .content .engaged-demographics .content,
.profile-container .profile-contents .analytics .analytics-demographics .content .reached-demographics .content {
    width: 100%;
    height: 64vh;
    padding-left: 5%;
    padding-right: 5%;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    border: none;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.profile-container .profile-contents .analytics .analytics-demographics .content .follower-demographics .content .row,
.profile-container .profile-contents .analytics .analytics-demographics .content .engaged-demographics .content .row,
.profile-container .profile-contents .analytics .analytics-demographics .content .reached-demographics .content .row {
    width: 100%;
    height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.profile-container .profile-contents .analytics .analytics-demographics .content .follower-demographics .content .row:nth-child(1),
.profile-container .profile-contents .analytics .analytics-demographics .content .engaged-demographics .content .row:nth-child(1),
.profile-container .profile-contents .analytics .analytics-demographics .content .reached-demographics .content .row:nth-child(1) {
    font-weight: bold;
}

.profile-container .profile-contents .analytics .analytics-demographics .content .follower-demographics .content .row .column:nth-child(1),
.profile-container .profile-contents .analytics .analytics-demographics .content .engaged-demographics .content .row .column:nth-child(1),
.profile-container .profile-contents .analytics .analytics-demographics .content .reached-demographics .content .row .column:nth-child(1) {
    width: 80%;
    line-height: 5vh;
}

.profile-container .profile-contents .analytics .analytics-demographics .content .follower-demographics .content .row .column:nth-child(2),
.profile-container .profile-contents .analytics .analytics-demographics .content .engaged-demographics .content .row .column:nth-child(2),
.profile-container .profile-contents .analytics .analytics-demographics .content .reached-demographics .content .row .column:nth-child(2) {
    width: 20%;
    line-height: 5vh;
    text-align: center;
}

.profile-container .profile-contents .analytics .analytics-demographics .content .follower-demographics .content .row:nth-child(1) .column:nth-child(2),
.profile-container .profile-contents .analytics .analytics-demographics .content .engaged-demographics .content .row:nth-child(1) .column:nth-child(2),
.profile-container .profile-contents .analytics .analytics-demographics .content .reached-demographics .content .row:nth-child(1) .column:nth-child(2) {
    background-color: #1878F1;
    color: #fff;
    border-radius: 15px;
}

.profile-container .profile-contents .analytics .analytics-demographics .age .follower-demographics .content {
    width: 100%;
} 
